<template>
  <travio-center-container grid-width="full" :pageTitle="pageTitle">
    <vx-card>
      <!--#region Pricing Id field-->
      <div v-if="isEditForm" class="vx-row mb-6">
        <div class="vx-col w-full align-center">
          <span>Pricing Rule Id</span>
        </div>
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            name="id"
            v-model="form.id"
            :disabled="true"
          />
        </div>
      </div>
      <!--#endregion Pricing Id field-->

      <div class="vx-row">
        <!--#region Pricing Rule Name -->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row mb-6">
            <div class="vx-col w-full align-center">
              <span>Pricing Rule Name*</span>
            </div>
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                name="name"
                v-model="form.name"
                v-validate="'required|max:100'"
                :disabled="readOnly"
              />
              <span class="text-danger text-sm">{{
                errors.first("name")
              }}</span>
            </div>
          </div>
        </div>
        <!--#endregion Pricing Rule Name ends -->

        <!--#region Priority field -->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <span>Priority (Lowest Applies First)</span>
            </div>
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                v-model="form.priority"
                type="number"
                name="priority"
                :disabled="readOnly"
                v-validate="'min_value:1|max_value:100'"
              />
              <span class="text-danger text-sm">{{
                errors.first("priority")
              }}</span>
            </div>
          </div>
        </div>
        <!--#endregion Priority field ends-->

        <!--#region Permissions field -->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row">

            <div class="vx-col w-full md:w-1/2">
              <div class="vx-col w-full">
                <span>Enable Rule:</span>
              </div>
              <div class="vx-col w-full">
                <vs-switch
                  class="mt-2"
                  v-model="form.isActive"
                  :disabled="readOnly"
                />
              </div>
            </div>
            <div class="vx-col w-full md:w-1/2">
              <div class="vx-col w-full">
                <span>Member Pricing:</span>
              </div>
              <div class="vx-col w-full">
                <vs-switch
                  class="mt-2"
                  v-model="form.isMember"
                  :disabled="readOnly"
                />
              </div>
            </div>
          
          </div>

        </div>
        <!--#endregion Permissions field ends -->

        <!--#region Modifier value field (Fixed or Percentage)-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row mb-6">
            <div class="vx-col w-full md:w-1/2">
              <div class="vx-col w-full">
                <span>Modifier Type:</span>
              </div>
              <div class="mt-4">
                <v-select
                  v-model="form.modifierType"
                  :options="helper.enums.modifierTypes"
                  :disabled="readOnly"
                />
              </div>
            </div>
            <div class="vx-col w-full md:w-1/2">
              <div class="vx-col w-full">
                <span>Modifier Value:</span>
              </div>
              <div class="mt-4">
                <!--#region plus and minus dropdown and its value field-->
                <vx-input-group
                  prependClasses="border border-solid border-grey border-r-0"
                >
                  <template slot="prepend">
                    <div class="prepend-text">
                      <vs-dropdown
                        vs-trigger-click
                        :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                        :disabled="readOnly"
                      >
                        <a class="flex items-center" href="#">
                          <span class="text-2xl">{{ plusOrMinus }}</span>
                          <feather-icon
                            icon="ChevronDownIcon"
                            svgClasses="h-4 w-4 ml-1"
                          />
                        </a>
                        <vs-dropdown-menu>
                          <vs-dropdown-item
                            class="text-2xl"
                            @click="updatePlusMinus('+')"
                          >
                            +
                          </vs-dropdown-item>
                          <vs-dropdown-item
                            class="text-2xl"
                            @click="updatePlusMinus('-')"
                          >
                            -
                          </vs-dropdown-item>
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </div>
                    <!-- default currency for Fixed type selection-->
                    <div
                      class="prepend-text"
                      v-if="form.modifierType === 'Fixed'"
                    >
                      <span class="text-success">{{
                        priceRulesFormData.appDefaultCurrency
                      }}</span>
                    </div>
                  </template>
                  <vs-input
                    v-model="form.modifierValue"
                    type="number"
                    name="modifierValue"
                    :disabled="readOnly"
                    v-validate="'min_value:0'"
                  />
                </vx-input-group>
                <span class="text-danger text-sm">{{
                  errors.first("modifierValue")
                }}</span>
                <!-- <vs-input class="mt-3" v-model="form.modifierValue" type="number" name="modifierValue" :disabled="readOnly" /> -->
                <!--#endregion plus and minus dropdown field ends-->
              </div>
            </div>
          </div>
        </div>
        <!--#endregion Modifier value field ends-->

        <!--#region Modifier Applies to -->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <span>Modifier Applies To:</span>
            </div>
            <div class="vx-col w-full">
              <v-select
                name="modifierAppliesTo"
                v-model="form.modifierAppliesTo"
                :options="helper.enums.modifierAppliesTo"
                :disabled="readOnly"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">{{
                errors.first("modifierAppliesTo")
              }}</span>
            </div>
          </div>
        </div>
        <!--#endregion Modifier Applies to -->

        <!--#region Days Before Travel -->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <span>Days Before Travel:</span>
            </div>
            <div class="vx-col w-full">
              <v-select
                multiple
                v-model="form.daysBeforeTravel"
                :options="helper.numberArray(1, 365)"
                :disabled="readOnly"
              />
              <span class="text-danger text-sm">{{
                errors.first("daysBeforeTravel")
              }}</span>
            </div>
          </div>
        </div>
        <!--#endregion Days Before Travel ends -->
        <div class="vx-col w-full md:w-full mb-6">
          <div class="vx-col w-full">
            <span>B2B Companies/Agents: (leaving blank will apply to all users, including B2C)</span>
          </div>
          <div class="vx-col w-full">
            <v-select
              multiple
              v-model="form.companyIDs"
              :options="priceRulesFormData.b2bCompanies"
              :disabled="readOnly"
              :reduce="(x) => x.code"
              name="B2B Companies"
            />
            <span class="text-danger text-sm">{{
              errors.first("B2B Companies")
            }}</span>
          </div>
        </div>
        <!--#region Passangers Allocations-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <span>Passenger Allocations:</span>
            </div>
            <template
              v-for="(passanger, passangerIndex) in form.passengerAllocations"
            >
              <!-- Adults -->
              <div class="vx-col w-full md:w-1/4" :key="passangerIndex + '0'">
                <div class="mt-4">
                  <label class="text-sm">Adults</label>
                  <vs-input
                    class="w-full"
                    type="number"
                    min="0"
                    :name="'adults_' + passangerIndex"
                    v-model="form.passengerAllocations[passangerIndex].adults"
                    v-validate="'required|min_value:0|max_value:100'"
                    :disabled="readOnly"
                  />
                  <!-- <span class="text-danger text-sm" v-if="errors.has('adults_' + passangerIndex)">{{ errors.first('adults_'+passangerIndex).replace('adults_'+passangerIndex, "adults") }}</span> -->
                </div>
              </div>
              <!-- Children -->
              <div class="vx-col w-full md:w-1/4" :key="passangerIndex + '1'">
                <div class="mt-4">
                  <label class="text-sm">Children</label>
                  <vs-input
                    class="w-full"
                    min="0"
                    type="number"
                    :name="'children_' + passangerIndex"
                    v-model="form.passengerAllocations[passangerIndex].children"
                    v-validate="'required|min_value:0|max_value:100'"
                    :disabled="readOnly"
                  />
                  <!-- <span class="text-danger text-sm" v-if="errors.has('children_' + passangerIndex)">{{ errors.first('children_'+passangerIndex).replace('children_'+passangerIndex, "children") }}</span> -->
                </div>
              </div>
              <!-- Infants -->
              <div class="vx-col w-full md:w-1/4" :key="passangerIndex + '2'">
                <div class="mt-4">
                  <label class="text-sm">Infants</label>
                  <vs-input
                    class="w-full"
                    min="0"
                    type="number"
                    :name="'infants_' + passangerIndex"
                    v-model="form.passengerAllocations[passangerIndex].infants"
                    v-validate="'required|min_value:0|max_value:100'"
                    :disabled="readOnly"
                  />
                  <!-- <span class="text-danger text-sm" v-if="errors.has('infants_' + passangerIndex)">{{ errors.first('infants_'+passangerIndex).replace('infants_'+passangerIndex, "infants") }}</span> -->
                </div>
              </div>
              <div class="vx-col w-full md:w-1/4" :key="passangerIndex + '3'">
                <div class="mt-3">
                  <!-- <label class="text-sm">Max</label> -->
                  <vs-button
                    @click="removeDynamicFields(passangerIndex, 'passenger')"
                    size="small"
                    class="mt-8"
                    type="border"
                    color="danger"
                    :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                    :disabled="readOnly"
                    >Remove</vs-button
                  >
                </div>
              </div>
            </template>

            <div class="vx-col w-full my-3">
              <vs-button
                @click="addDynamicFields('passenger')"
                size="small"
                type="border"
                color="success"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Add</vs-button
              >
            </div>
          </div>
        </div>

        <!--#endregion Passangers Allocations ends-->

        <!--#region Price Range field  -->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <span>Price Ranges:</span>
            </div>
            <template v-for="(price, priceIndex) in form.prices">
              <!-- Min -->
              <div class="vx-col w-full md:w-1/3" :key="priceIndex + '0'">
                <div class="mt-4">
                  <label class="text-sm">Min</label>
                  <vs-input
                    class="w-full"
                    min="0"
                    type="number"
                    :name="'priceMin_' + priceIndex"
                    v-model="form.prices[priceIndex].min"
                    v-validate="{
                      rules: {
                        required: true,
                        max_value: form.prices[priceIndex].max
                          ? form.prices[priceIndex].max
                          : false,
                      },
                    }"
                    :disabled="readOnly"
                  />
                  <span
                    class="text-danger text-sm"
                    v-if="errors.has('priceMin_' + priceIndex)"
                    >{{
                      errors
                        .first("priceMin_" + priceIndex)
                        .replace("The priceMin_" + priceIndex, "Min price ")
                    }}</span
                  >
                </div>
              </div>
              <!-- Max -->
              <div class="vx-col w-full md:w-1/3" :key="priceIndex + '1'">
                <div class="mt-4">
                  <label class="text-sm">Max</label>
                  <vs-input
                    class="w-full"
                    min="0"
                    type="number"
                    :name="'priceMax_' + priceIndex"
                    v-model="form.prices[priceIndex].max"
                    v-validate="{
                      rules: {
                        required: true,
                        min_value: form.prices[priceIndex].min
                          ? form.prices[priceIndex].min
                          : false,
                      },
                    }"
                    :disabled="readOnly"
                  />
                  <span
                    class="text-danger text-sm"
                    v-if="errors.has('priceMax_' + priceIndex)"
                    >{{
                      errors
                        .first("priceMax_" + priceIndex)
                        .replace("The priceMax_" + priceIndex, "Max price ")
                    }}</span
                  >
                </div>
              </div>

              <div class="vx-col w-full md:w-1/3" :key="priceIndex + '3'">
                <div class="mt-3">
                  <!-- <label class="text-sm">Remove</label> -->
                  <vs-button
                    @click="removeDynamicFields(priceIndex, 'priceRange')"
                    size="small"
                    class="mt-8"
                    type="border"
                    color="danger"
                    :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                    :disabled="readOnly"
                    >Remove</vs-button
                  >
                </div>
              </div>
            </template>

            <div class="vx-col w-full my-3">
              <vs-button
                @click="addDynamicFields('priceRange')"
                size="small"
                type="border"
                color="success"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Add</vs-button
              >
            </div>
          </div>
        </div>
        <!--#endregion Price Range  field ends -->

        <!--#region Booking Dates field  -->
        <div class="vx-col w-full">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <span>Booking Dates:</span>
            </div>
            <template v-for="(bookingDate, dateIndex) in form.bookingDates">
              <!-- Min -->
              <div class="vx-col w-full md:w-1/5" :key="dateIndex + '0'">
                <div class="mt-4">
                  <label class="text-sm">Min</label>
                  <flat-pickr
                    v-model="form.bookingDates[dateIndex].min"
                    :name="'bookMin_' + dateIndex"
                    v-validate="'required'"
                    :config="{
                      dateFormat:'Y-m-d',
                      maxDate: form.bookingDates[dateIndex].max,
                    }"
                    class="w-full"
                    :disabled="readOnly"
                  />
                  <span
                    class="text-danger text-sm"
                    v-if="errors.has('bookMin_' + dateIndex)"
                    >{{
                      errors
                        .first("bookMin_" + dateIndex)
                        .replace(
                          "The bookMin_" + dateIndex + " field is required",
                          "Min date is required"
                        )
                    }}</span
                  >
                </div>
              </div>
              <!-- Max -->
              <div class="vx-col w-full md:w-1/5" :key="dateIndex + '1'">
                <div class="mt-4">
                  <label class="text-sm">Max</label>
                  <flat-pickr
                    v-model="form.bookingDates[dateIndex].max"
                    :config="{
                      dateFormat:'Y-m-d',
                      minDate: form.bookingDates[dateIndex].min,
                    }"
                    class="w-full"
                    :name="'bookMax_' + dateIndex"
                    v-validate="'required'"
                    :disabled="readOnly"
                  />
                  <span
                    class="text-danger text-sm"
                    v-if="errors.has('bookMax_' + dateIndex)"
                    >{{
                      errors
                        .first("bookMax_" + dateIndex)
                        .replace(
                          "The bookMax_" + dateIndex + " field is required",
                          "Max date is required"
                        )
                    }}</span
                  >
                </div>
              </div>
              <!-- Days -->
              <div class="vx-col w-full md:w-2/5" :key="dateIndex + '2'">
                <div class="mt-4">
                  <label class="text-sm">Days</label>
                  <v-select
                    class="w-full"
                    name="daysOfWeek"
                    multiple
                    v-model="form.bookingDates[dateIndex].daysOfWeek"
                    :options="helper.enums.daysOfWeek"
                    :reduce="(option) => option.code"
                    v-validate="{
                      rules: { required: false },
                    }"
                    :disabled="readOnly"
                  />
                  <!-- <span class="text-danger text-sm"  v-show="errors.has('daysOfWeek')">{{ errors.first('daysOfWeek') }}</span> -->
                </div>
              </div>
              <div class="vx-col w-full md:w-1/5" :key="dateIndex + '3'">
                <div class="mt-3">
                  <!-- <label class="text-sm">Remove</label> -->
                  <vs-button
                    @click="removeDynamicFields(dateIndex, 'bookingDates')"
                    size="small"
                    class="mt-8"
                    type="border"
                    color="danger"
                    :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                    :disabled="readOnly"
                    >Remove</vs-button
                  >
                </div>
              </div>
            </template>

            <div class="vx-col w-full my-3">
              <vs-button
                @click="addDynamicFields('bookingDates')"
                size="small"
                type="border"
                color="success"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Add</vs-button
              >
            </div>
          </div>
        </div>
        <!--#endregion Booking Dates field ends -->

        <!--#region Travel Dates field  -->
        <div class="vx-col w-full">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <span>Travel Dates:</span>
            </div>
            <template v-for="(travelDate, dateIndex) in form.travelDates">
              <!-- Min -->
              <div class="vx-col w-full md:w-1/5" :key="dateIndex + '0'">
                <div class="mt-4">
                  <label class="text-sm">Min</label>
                  <flat-pickr
                    v-model="form.travelDates[dateIndex].min"
                    :config="{
                      dateFormat:'Y-m-d',
                      maxDate: form.travelDates[dateIndex].max,
                    }"
                    class="w-full"
                    :name="'travelMin_' + dateIndex"
                    v-validate="'required'"
                    :disabled="readOnly"
                  />
                  <span
                    class="text-danger text-sm"
                    v-if="errors.has('travelMin_' + dateIndex)"
                    >{{
                      errors
                        .first("travelMin_" + dateIndex)
                        .replace(
                          "The travelMin_" + dateIndex + " field is required",
                          "Min date is required"
                        )
                    }}</span
                  >
                </div>
              </div>
              <!-- Max -->
              <div class="vx-col w-full md:w-1/5" :key="dateIndex + '1'">
                <div class="mt-4">
                  <label class="text-sm">Max</label>
                  <flat-pickr
                    v-model="form.travelDates[dateIndex].max"
                    :config="{
                      dateFormat:'Y-m-d',
                      minDate: form.travelDates[dateIndex].min,
                    }"
                    class="w-full"
                    :name="'travelMax_' + dateIndex"
                    :disabled="readOnly"
                    v-validate="'required'"
                  />
                  <span
                    class="text-danger text-sm"
                    v-if="errors.has('travelMax_' + dateIndex)"
                    >{{
                      errors
                        .first("travelMax_" + dateIndex)
                        .replace(
                          "The travelMax_" + dateIndex + " field is required",
                          "Max date is required"
                        )
                    }}</span
                  >
                </div>
              </div>
              <!-- Days -->
              <div class="vx-col w-full md:w-2/5" :key="dateIndex + '2'">
                <div class="mt-4">
                  <label class="text-sm">Days</label>
                  <v-select
                    class="w-full"
                    name="daysOfWeek"
                    multiple
                    v-model="form.travelDates[dateIndex].daysOfWeek"
                    :options="helper.enums.daysOfWeek"
                    :reduce="(option) => option.code"
                    v-validate="{
                      rules: { required: false },
                    }"
                    :disabled="readOnly"
                  />
                </div>
              </div>
              <div class="vx-col w-full md:w-1/5" :key="dateIndex + '3'">
                <div class="mt-3">
                  <!-- <label class="text-sm">Max</label> -->
                  <vs-button
                    @click="removeDynamicFields(dateIndex, 'travelDates')"
                    size="small"
                    class="mt-8"
                    type="border"
                    color="danger"
                    :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                    :disabled="readOnly"
                    >Remove</vs-button
                  >
                </div>
              </div>
            </template>

            <div class="vx-col w-full my-3">
              <vs-button
                @click="addDynamicFields('travelDates')"
                size="small"
                type="border"
                color="success"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Add</vs-button
              >
            </div>
          </div>
        </div>
        <!--#endregion Travel Dates field ends -->
      </div>

      <!--#endregion Product Type field ends -->

      <!--#region Search Type field -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <span>Search Types: (required)</span>
        </div>
        <template v-for="(searchType, searchIndex) in helper.enums.searchTypes">
          <div class="vx-col flex flex-wrap w-1/2 mb-4 mt-4" :key="searchIndex">
            <vs-checkbox
              :vs-value="searchType.value"
              :title="searchType"
              v-model="form.searchTypes"
              :disabled="readOnly"
              >{{ searchType.displayName }}</vs-checkbox
            >
          </div>
        </template>
      </div>
      <!--#endregion Search Type field ends-->

      <!--#region Product Type field -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <span>Product Types: (required)</span>
        </div>
        <template v-for="(product, productIndex) in helper.enums.productTypes">
          <div
            class="vx-col flex flex-wrap w-1/2 mb-4 mt-4"
            :key="productIndex"
          >
            <vs-checkbox
              :vs-value="product.value"
              :title="product"
              v-model="form.productTypes"
              :disabled="readOnly"
              >{{ product.displayName }}</vs-checkbox
            >
          </div>
          <component
            :key="productIndex + '1'"
            class="vx-col w-full"
            v-show="
              product.componentName && form.productTypes.includes(product.value)
            "
            :is="product.componentName"
            :form="form"
            :helper="helper"
            :priceRulesFormData="priceRulesFormData"
            :readOnly="readOnly"
            :ref="pricingRuleChildComponent"
          ></component>
        </template>
      </div>

      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>

      <div class="flex flex-wrap justify-end">
        <vs-button
          v-if="!readOnly"
          @click="handleCancel"
          color="danger"
          class="ml-auto mt-4 mr-4"
          >Cancel</vs-button
        >
        <vs-button v-if="!readOnly" @click="handleSubmit" class="mt-4"
          >Save</vs-button
        >
        <vs-button
          v-if="readOnly && !isSystemRole"
          @click="handleEditMode"
          class="ml-auto mt-4 mr-4"
          >Edit</vs-button
        >
        <vs-button v-if="readOnly" @click="handleCancel" class="mt-4"
          >Back</vs-button
        >
      </div>
      <!--#region product type sub-criteria not selection warning-->
      <vs-prompt
        title=""
        accept-text="Proceed"
        @cancel="proceedSaving = false"
        @close="proceedSaving = false"
        @accept="
          proceedSaving = true;
          handleSubmit();
        "
        :active.sync="displayProductTypeWarning"
      >
        <div class="vx-row mt-2 mb-4">
          <div class="vx-col">
            <p>
              Not all of the additional criterias have been set up for following
              product types
            </p>
            <br />
            <p>
              <strong> {{ productTypesWithoutRule.join() }} </strong>
            </p>
            <br />
            <p>If you want to save anyway, click proceed button</p>
          </div>
        </div>
      </vs-prompt>
      <!--#endregion product type sub-criteria not selection warning ends-->
    </vx-card>
  </travio-center-container>
</template>

<script>
import { Form } from "@/core-modules/form-framework/Form.js";
import helper from "./priceRuleHelper";
import priceRuleStore from "./priceRuleStore";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import _ from "lodash";
import { CustomValidator } from "./PricingRuleEditorValidator.js";
import FlightRuleCriteria from "./sub-criterias/FlightRuleCriteria.vue";
import AirportExtrasCriteria from "./sub-criterias/AirportExtrasCriteria.vue";
import TicketsCriteria from "./sub-criterias/TicketsCriteria.vue";
import TransferRuleCriteria from "./sub-criterias/TransferRuleCriteria.vue";
import CarCriteria from "./sub-criterias/CarCriteria.vue";
import AccommodationRuleCriteria from "./sub-criterias/AccommodationRuleCriteria.vue";
import PackageHolidayRuleCriteria from "./sub-criterias/PackageHolidayRuleCriteria.vue";
import CruiseRuleCriteria from "./sub-criterias/CruiseRuleCriteria.vue";

const customvalidator = new CustomValidator();
customvalidator.setupErrorMessages();

export default {
  components: {
    flatPickr,
    FlightRuleCriteria,
    AirportExtrasCriteria,
    TicketsCriteria,
    TransferRuleCriteria,
    AccommodationRuleCriteria,
    CarCriteria,
    PackageHolidayRuleCriteria,
    CruiseRuleCriteria,
  },
  provide() {
    return {
      $validator: this.$validator,
    };
  },
  // see https://router.vuejs.org/guide/essentials/passing-props.html#object-mode
  props: {
    readOnly: { type: Boolean, default: true },
  },
  data() {
    return {
      helper: helper,
      form: new Form(_.cloneDeep(helper.priceRuleModel)),
      plusOrMinus: "+",
      pricingRuleChildComponent: "pricingRuleChildComponent",
      productTypesWithoutRule: [],
      displayProductTypeWarning: false,
      proceedSaving: false,
    };
  },
  // props: [ 'companyId', 'roleId' ],
  computed: {
    priceRulesFormData() {
      return this.$store.state.priceRuleStore.formData;
    },
    preferedDateFormat() {
      // Preferred date format is not accepted by flatpicker so revert to default format
      return 'Y-m-d'
    },
    pageTitle() {
      return this.readOnly
        ? "View Pricing Rule"
        : this.$route.params.pricingRuleId > 0
        ? "Edit Pricing Rule"
        : "Add Pricing Rule";
    },
    isEditForm() {
      return this.$route.params.pricingRuleId > 0 ? true : false;
    },
    isSystemRole() {
      return this.$route.query.isSystem;
    },
    priceRulesData() {
      return this.$store.state.priceRuleStore.priceRules;
    },
  },
  methods: {
    handleSubmit() {
      // It triggers validateform() method in all of its child component  which returns promise.
      let allForm = this.$refs.pricingRuleChildComponent.map((x) =>
        x.validateform()
      );
      //It triggers validation in this component itself and store the promise in allForm Array
      allForm.push(this.$validator.validateAll());
      Promise.all(allForm).then((result) => {
        if (!result.includes(false)) {
          if (!this.proceedSaving) {
            this.additionalCriteria(this.form.data());
          }
          if (this.proceedSaving) {
            this.$vs.loading();
            const action = this.isEditForm
              ? "priceRuleStore/updatePriceRule"
              : "priceRuleStore/addPriceRule";
            const payload = {
              data: this.form.data(),
              applicationId: this.$route.params.applicationId,
              priceRuleId: this.$route.params.pricingRuleId,
            };
            this.settingNullToNotSelectedProductType(payload);

            if (!this.isEditForm) {
              payload.data.applicationId = this.$route.params.applicationId;
            }
            //if percentage or fixed value is choosen negative, it should be bounded as negative value
            if (this.plusOrMinus === "-") {
              const negativeValue =
                this.plusOrMinus + payload.data.modifierValue;
              payload.data.modifierValue = parseFloat(negativeValue);
            }

            this.$store
              .dispatch(action, payload)
              .then((response) => {
                const successMsg = this.isEditForm ? "updated" : "added new";
                this.$_notifySuccess(`Successfully ${successMsg} Pricing Rule`);
                this.$router.push(
                  `/applications/${this.$route.params.applicationId}/pricingRules`
                );
              })
              .catch((error) =>
                this.$_notifyFailureByResponseData(error.response.data)
              )
              .finally(() => this.$vs.loading.close());
          }
        } else {
          this.$_notifyFailure(
            "Invalid form data. Please correct all form fields."
          );
        }
      });
    },
    settingNullToNotSelectedProductType(payload) {
      for (let i = 0; i < payload.data.productTypes.length; i++) {
        if (!payload.data.productTypes.includes("Flights")) {
          payload.data.flightRuleCriteria = null;
        }
        if (!payload.data.productTypes.includes("Accommodation")) {
          payload.data.accommodationRuleCriteria = null;
        }
        if (!payload.data.productTypes.includes("TicketsAttractions")) {
          payload.data.ticketsRuleCriteria = null;
        }
        if (!payload.data.productTypes.includes("Transfers")) {
          payload.data.transferRuleCriteria = null;
        }
        if (!payload.data.productTypes.includes("CarRental")) {
          payload.data.carRuleCriteria = null;
        }
        if (!payload.data.productTypes.includes("AirportExtras")) {
          payload.data.airportExtrasRuleCriteria = null;
        }
        if (!payload.data.productTypes.includes("Packages")) {
          payload.data.packageHolidayRuleCriteria = null;
        }
      }
    },
    settingFormObjectToNullProductType(dataToBeEdited) {
      if (dataToBeEdited.flightRuleCriteria == null) {
        dataToBeEdited.flightRuleCriteria = helper.flightCriteria;
      }
      if (dataToBeEdited.accommodationRuleCriteria == null) {
        dataToBeEdited.accommodationRuleCriteria = helper.accommodationCriteria;
      }
      if (dataToBeEdited.packageHolidayRuleCriteria == null) {
        dataToBeEdited.packageHolidayRuleCriteria = helper.packageHolidayCriteria;
      }
      if (dataToBeEdited.carRuleCriteria == null) {
        dataToBeEdited.carRuleCriteria = helper.carCriteria;
      }
      if (dataToBeEdited.transferRuleCriteria == null) {
        dataToBeEdited.transferRuleCriteria = helper.transferCriteria;
      }
      if (dataToBeEdited.ticketsRuleCriteria == null) {
        dataToBeEdited.ticketsRuleCriteria = helper.ticketsCriteria;
      }
      if (dataToBeEdited.airportExtrasRuleCriteria == null) {
        dataToBeEdited.airportExtrasRuleCriteria = helper.airportExtrasCriteria;
      }
    },
    additionalCriteria(formData) {
      this.productTypesWithoutRule = [];
      for (let i = 0; i < formData.productTypes.length; i++) {
        if (formData.productTypes[i] === "Flights") {
          if (
            Object.values(formData.flightRuleCriteria).some(
              (element) => element == null
            )
          ) {
            this.productTypesWithoutRule.push("Flights");
          }
        }
        if (formData.productTypes[i] === "Accommodation") {
          if (
            Object.values(formData.accommodationRuleCriteria).some(
              (element) => element == null
            )
          ) {
            this.productTypesWithoutRule.push("Accommodation");
          }
        }
        if (formData.productTypes[i] === "Packages") {
          if (
            Object.values(formData.packageHolidayRuleCriteria).some(
              (element) => element == null
            )
          ) {
            this.productTypesWithoutRule.push("Packages");
          }
        }
        if (formData.productTypes[i] === "TicketsAttractions") {
          if (
            Object.values(formData.ticketsRuleCriteria).some(
              (element) => element == null
            )
          ) {
            this.productTypesWithoutRule.push("Tickets Attractions");
          }
        }
        if (formData.productTypes[i] === "Transfers") {
          if (
            Object.values(formData.transferRuleCriteria).some(
              (element) => element == null
            )
          ) {
            this.productTypesWithoutRule.push("Transfers");
          }
        }
        if (formData.productTypes[i] === "CarRental") {
          if (
            Object.values(formData.carRuleCriteria).some(
              (element) => element == null
            )
          ) {
            this.productTypesWithoutRule.push("CarRental");
          }
        }
        if (formData.productTypes[i] === "AirportExtras") {
          if (
            Object.values(formData.airportExtrasRuleCriteria).some(
              (element) => element == null
            )
          ) {
            this.productTypesWithoutRule.push("Airport Extras");
          }
        }
        if (
          i === formData.productTypes.length - 1 &&
          this.productTypesWithoutRule.length > 0
        ) {
          this.displayProductTypeWarning = true;
        }
      }
      if (this.productTypesWithoutRule.length > 0) {
        this.proceedSaving = false;
      } else {
        this.proceedSaving = true;
      }
    },
    handleCancel() {
      this.$router.push({
        name: "pricing-rules-list",
        params: { applicationId: this.$route.params.applicationId },
      });
    },
    handleEditMode() {
      this.$router
        .push(
          `/applications/${this.$route.params.applicationId}/pricingRules/${this.$route.params.pricingRuleId}/edit`
        )
        .catch((error) => {
          console.error(error);
        });
    },
    updatePlusMinus(plusOrMinus) {
      this.plusOrMinus = plusOrMinus;
    },
    addDynamicFields(fieldType) {
      if (fieldType === "bookingDates") {
        this.form.bookingDates.push(_.cloneDeep(helper.dateRange));
      }
      if (fieldType === "travelDates") {
        this.form.travelDates.push(_.cloneDeep(helper.dateRange));
      }
      if (fieldType === "passenger") {
        this.form.passengerAllocations.push(
          _.cloneDeep(helper.passengerAllocation)
        );
      }
      if (fieldType === "priceRange") {
        this.form.prices.push(_.cloneDeep(helper.priceRange));
      }
    },
    removeDynamicFields(index, fieldType) {
      if (fieldType === "bookingDates") {
        this.form.bookingDates.splice(index, 1);
      }
      if (fieldType === "travelDates") {
        this.form.travelDates.splice(index, 1);
      }
      if (fieldType === "passenger") {
        this.form.passengerAllocations.splice(index, 1);
      }
      if (fieldType === "priceRange") {
        this.form.prices.splice(index, 1);
      }
    },
  },
  async created() {
    if (!priceRuleStore.isRegistered) {
      this.$store.registerModule("priceRuleStore", priceRuleStore);
      priceRuleStore.isRegistered = true;
    }

    //#region fetch required data from api and store in state
    this.$vs.loading();

    const priceRulePromise =  this.$store.dispatch("priceRuleStore/fetchPriceRules",this.$route.params.applicationId);
    const formDataPromise =  this.$store.dispatch("priceRuleStore/fetchFormData",this.$route.params.applicationId);

    const [priceRules, formData] = await Promise.all([priceRulePromise, formDataPromise])
    let userDateFormat = this.$store.state.accountStore.generalSettings.dateFormat;
    if (!userDateFormat) {
      return this.$store.dispatch("accountStore/loadAccount");
    }
this.$vs.loading.close()

    //#region if this is Edit/View form, fetch data from api and bind it to form data
    if (this.$route.params.pricingRuleId > 0) {
      this.$vs.loading();
      this.$http
        .get(
          `api/pricerules/${this.$route.params.applicationId}/rules/${this.$route.params.pricingRuleId}`
        )
        .then((response) => {
          const dataToBeEdited = JSON.parse(response.data.ruleData);
          //check if data for given rule-id exist
          if (!dataToBeEdited) {
            this.$_notifyFailure("No data available for given rule-id");
            this.$router.push(
              `/applications/${this.$route.params.applicationId}/pricingRules`
            );
          }
          //if modifierValue value is negative, negative sign should be removed before assigning it to form data
          if (Math.sign(dataToBeEdited.modifierValue) < 0) {
            this.plusOrMinus = "-";
            dataToBeEdited.modifierValue = Math.abs(
              dataToBeEdited.modifierValue
            );
          }
          this.settingFormObjectToNullProductType(dataToBeEdited);
          Object.assign(this.form, dataToBeEdited);
        })
        .catch((error) => {
          this.$_notifyFailureByResponseData(error.response.data);
          this.$router.push(
            `/applications/${this.$route.params.applicationId}/pricingRules`
          );
        })
        .finally(() => this.$vs.loading.close());
    }
    //#endregion
  },
};
</script>

<style>
</style>
