<template>
  <div>
    <vx-card class="" no-shadow card-border>
      <div class="vx-row mb-6">
        <div class="vx-col w-full align-center my-2">
          <span>Flight Rule Criteria:</span>
        </div>
        <!--#region  suppliers field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Suppliers:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="flightLocalForm.flightRuleCriteria.suppliers"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.flightSupplier"
            />
          </div>
        </div>
        <!--#endregion  suppliers field-->

        <!--#region  Suppliers Code field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Suppliers Codes:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="flightLocalForm.flightRuleCriteria.supplierCodes"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.accomSupplierCode"
            />
          </div>
        </div>
        <!--#endregion  Suppliers Codes field-->

        
      </div>
      <div class="vx-row mb-6">
        
        <!--#region  Airline Carriers field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <div class="flex flex-wrap justify-start">
              <div class="mr-2">Airline Carrier Codes:</div>
              <vs-checkbox
                :vs-value="true"
                v-model="flightLocalForm.flightRuleCriteria.excludeCarriers"
                :disabled="readOnly"
                >Exclude Selected Carriers</vs-checkbox>
            </div>
            
          </div>
          <div class="mt-4">
            <v-select
              multiple
              name="airlineCode"
              v-model="flightLocalForm.flightRuleCriteria.carrierCodes"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.carrierCode"
              v-validate="{
                rules: flightLocalForm.productTypes.includes('Flights') ? { required: false, validCarrierCode: true } : {},
              }"
            />
            <span class="text-danger text-sm">{{
              errors.first("airlineCode")
            }}</span>
          </div>
        </div>
        <!--#endregion  Airline Carriers field-->

        <!--#region  Cabin Classes field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Cabin Classes:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="flightLocalForm.flightRuleCriteria.cabinClasses"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="flightLocalHelper.enums.cabinClasses"
            />
          </div>
        </div>
        <!--#endregion  Cabin Classes field-->

      </div>

      <div class="vx-row mb-6">
        <!--#region  Fare Types field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Fare Types:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="flightLocalForm.flightRuleCriteria.fareTypes"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="flightLocalHelper.enums.fareTypes"
            />
          </div>
        </div>
        <!--#endregion  Fare Types field-->
      </div>

      <!--#region Flight Routes  field  -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full text-sm">
          <span>Flight Route:</span>
        </div>
        <template
          v-for="(route, routeIndex) in flightLocalForm.flightRuleCriteria
            .routes"
        >
          <!-- Origin -->
          <div class="vx-col w-full md:w-2/5" :key="routeIndex + '0'">
            <div class="mt-4">
              <label class="text-sm">Origin</label>
              <v-select
                class="w-full"
                :name="'origin_' + routeIndex"
                v-model="
                  flightLocalForm.flightRuleCriteria.routes[routeIndex].origin
                "
                :options="routeIndex === currentActiveRouteIndex ? routeOriginOptions : priceRulesFormData.routeCode"
                @search="onSearch"
                @search:focus="activeIndex(routeIndex,'origin')"
                :reduce="(option) => option.code"
                :selectable="
                  (option) =>
                    !(
                      option.code ===
                      flightLocalForm.flightRuleCriteria.routes[routeIndex]
                        .destination
                    )
                "
                v-validate="{
                  rules: { required: true, flightRouteCode: true },
                }"
                :disabled="readOnly"
              />
              <!-- <vs-input
                class="w-full"
                :name="'origin_' + routeIndex"
                v-model="
                  flightLocalForm.flightRuleCriteria.routes[routeIndex].origin
                "
                v-validate="{
                  rules: { required: false, regex: /^[A-Z]{2,3}$/ },
                }"
                :disabled="readOnly"
              /> -->
              <span
                class="text-danger text-sm"
                v-if="errors.has('origin_' + routeIndex)"
                >{{
                  errors
                    .first("origin_" + routeIndex)
                    .replace(
                      "The origin_" + routeIndex ,
                      "Origin route "
                    )
                }}</span
              >
            </div>
          </div>
          <!-- Destination -->
          <div class="vx-col w-full md:w-2/5" :key="routeIndex + '1'">
            <div class="mt-4">
              <label class="text-sm">Destination</label>
              <v-select
                class="w-full"
                :name="'flightDestination_' + routeIndex"
                v-model="
                  flightLocalForm.flightRuleCriteria.routes[routeIndex]
                    .destination
                "
                :options="routeIndex === currentActiveRouteIndex ? routeDestOptions : priceRulesFormData.routeCode"
                @search="onSearch"
                @search:focus="activeIndex(routeIndex,'dest')"
                :reduce="(option) => option.code"
                :selectable="
                  (option) =>
                    !(
                      option.code ===
                      flightLocalForm.flightRuleCriteria.routes[routeIndex]
                        .origin
                    )
                "
                v-validate="{
                  rules: { required: true, flightRouteCode: true },
                }"
                :disabled="readOnly"
              />
              <!-- <vs-input
                class="w-full"
                :name="'flightDestination_' + routeIndex"
                v-model="
                  flightLocalForm.flightRuleCriteria.routes[routeIndex]
                    .destination
                "
                v-validate="{
                  rules: { required: false, regex: /^[A-Z]{2,3}$/ },
                }"
                :disabled="readOnly"
              /> -->
              <span
                class="text-danger text-sm"
                v-if="errors.has('flightDestination_' + routeIndex)"
                >{{
                  errors
                    .first("flightDestination_" + routeIndex)
                    .replace(
                      "The flightDestination_" +
                        routeIndex ,
                      "Destination route "
                    )
                }}</span
              >
            </div>
          </div>
          <div class="vx-col w-full md:w-1/5" :key="routeIndex + '2'">
            <div class="mt-3">
              <!-- <label class="text-sm">Max</label> -->
              <vs-button
                @click="removeRouteFields(routeIndex)"
                size="small"
                class="mt-8"
                type="border"
                color="danger"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Remove</vs-button
              >
            </div>
          </div>
        </template>

        <div class="vx-col w-full my-3">
          <vs-button
            @click="addRouteFields"
            size="small"
            type="border"
            color="success"
            :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="readOnly"
            >Add</vs-button
          >
        </div>
      </div>
      <!--#endregion Flight Routes field ends -->

      <!--#region Fare Bases field -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full text-sm">
          <span>Fare Bases:</span>
        </div>
        <template
          v-for="(fareBase, fareIndex) in flightLocalForm.flightRuleCriteria
            .fareBases"
        >
          <!-- Origin -->
          <div class="vx-col w-full md:w-1/3" :key="fareIndex + '0'">
            <div class="mt-4">
              <label class="text-sm">MatchType</label>
              <v-select
                class="w-full"
                :name="'matchType_' + fareIndex"
                v-model="
                  flightLocalForm.flightRuleCriteria.fareBases[fareIndex]
                    .matchType
                "
                :options="flightLocalHelper.enums.matchTypes"
                :reduce="(option) => option.code"
                v-validate="{ rules: { required: true} }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('matchType_' + fareIndex)"
                >{{
                  errors
                    .first("matchType_" + fareIndex)
                    .replace("matchType_" + fareIndex, "Match type")
                }}</span
              >
            </div>
          </div>
          <!-- Value -->
          <div class="vx-col w-full md:w-1/3" :key="fareIndex + '1'">
            <div class="mt-4">
              <label class="text-sm">Value</label>
              <vs-input
                class="w-full"
                :name="'fareValue_' + fareIndex"
                v-model="
                  flightLocalForm.flightRuleCriteria.fareBases[fareIndex].value
                "
                v-validate="{ rules: { required: true, max: 10 } }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('fareValue_' + fareIndex)"
                >{{
                  errors
                    .first("fareValue_" + fareIndex)
                    .replace("fareValue_" + fareIndex, "value")
                }}</span
              >
            </div>
          </div>
          <div class="vx-col w-full md:w-1/3" :key="fareIndex + '2'">
            <div class="mt-3">
              <!-- <label class="text-sm">Max</label> -->
              <vs-button
                @click="removeFlightDynamicFields(fareIndex, 'fareBase')"
                size="small"
                class="mt-8"
                type="border"
                color="danger"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Remove</vs-button
              >
            </div>
          </div>
        </template>

        <div class="vx-col w-full my-3">
          <vs-button
            @click="addFlightDynamicFields('fareBase')"
            size="small"
            type="border"
            color="success"
            :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="readOnly"
            >Add</vs-button
          >
        </div>
      </div>
      <!--#endregion Fare Bases field ends -->

    </vx-card>
  </div>
</template>

<script>
import _ from "lodash";
import { CustomValidator } from "../PricingRuleEditorValidator.js";
const customvalidator = new CustomValidator();
customvalidator.setupErrorMessages();

export default {
  name: "FlightRuleCriteria",
  components: {},
  inject: ["$validator"],
  props: {
    helper: {
      type: Object,
    },
    form: {
      type: Object,
    },
    priceRulesFormData: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      flightLocalHelper: this.helper,
      flightLocalForm: this.form,
      awaitingSearch: false,
      routeOriginOptions: [],
      routeDestOptions: [],
      currentActiveRouteIndex : 0,
      currentRouteType : '',
    };
  },
  created() {
    //#region  validation custom extended method

    /**
     *custom validator which is used to validate Airline Carrier Code Format
     */
    this.$validator.extend("validCarrierCode", {
      getMessage: (field) =>
        "Only 2 or 3-characters (uppercase letter and number) are allowed",
      validate: (value) => {
        let isValid = true;
        value.every((element) => {
          const carrierCodeRegex = "^[A-Z0-9]{2,3}$";
          isValid = new RegExp(carrierCodeRegex).test(element);
          return isValid;
        });
        return isValid;
      },
    });
    /**
     *custom validator which is used to validate Flight Route Origin and Destination  Code Format
     */
    this.$validator.extend("flightRouteCode", {
      getMessage: (field) => "Only 2 or 3 uppercase letters are allowed",
      validate: (value) => {
        const routeCodeRegex = "^[A-Z]{2,3}$";
        return new RegExp(routeCodeRegex).test(value);
      },
    });

    //#endregion
  },

  methods: {
    validateform() {
      return this.$validator.validateAll();
    },

    //#region  Adding dynamic route fields in the form
    addRouteFields() {
      this.flightLocalForm.flightRuleCriteria.routes.push(
        _.cloneDeep(this.flightLocalHelper.flightRoute)
      );
    },
    addFlightDynamicFields(fieldType) {
      if (fieldType === "fareBase") {
        this.flightLocalForm.flightRuleCriteria.fareBases.push(
          _.cloneDeep(this.flightLocalHelper.stringMatch)
        );
      }
    },
    //#endregion

    //#region  Removing dynamic route fields in the form
    removeRouteFields(index) {
      this.flightLocalForm.flightRuleCriteria.routes.splice(index, 1);
    },
    removeFlightDynamicFields(index, fieldType) {
      if (fieldType === "fareBase") {
        this.flightLocalForm.flightRuleCriteria.fareBases.splice(index, 1);
      }
    },
    //#endregion

    onSearch(search, loading) {     
       if (!this.awaitingSearch) {         
         loading(true);
          setTimeout(() => {
       const result = this.priceRulesFormData.routeCode.filter(x=>x.label.toLowerCase().includes(search.toLowerCase()));
       if(this.currentRouteType === 'origin'){
         this.routeOriginOptions = result;
       }
       if(this.currentRouteType === 'dest'){
         this.routeDestOptions = result;
       }
            this.awaitingSearch = false;
            loading(false);
          }, 0); // 1 sec delay
        }
        this.awaitingSearch = true;
    },
    activeIndex(index,routeType){     
      if(index !== this.currentActiveRouteIndex){
        this.routeOriginOptions = this.priceRulesFormData.routeCode == null ? [] : this.priceRulesFormData.routeCode;
        this.routeDestOptions = this.priceRulesFormData.routeCode == null ? [] : this.priceRulesFormData.routeCode;
      }
      this.currentActiveRouteIndex = index;
      this.currentRouteType = routeType;
    },
  },
  /**
   * When check box is unchecked, all its related form  should be reset
   * In this case FlightRuleCriteria(i.e. flightLocalForm.flightRuleCriteria) should  be reset
   */
  watch: {
    "form.productTypes": {
      deep: true,
      immediate: true,
      handler: function (newValue, oldValue) {
        if (
          oldValue &&
          oldValue.length > 0 &&
          oldValue.includes("Flights") &&
          newValue &&
          !newValue.includes("Flights")
        ) {
          this.flightLocalForm.flightRuleCriteria = _.cloneDeep(
            this.flightLocalHelper.flightCriteria
          );
        }
      },
    },
    /**Assign routeCode to routeOption as default option-data  */
     "priceRulesFormData": {
      deep: true,
      immediate: true,
      handler: function (newValue, oldValue) {       
        this.routeOriginOptions = this.priceRulesFormData.routeCode == null ? [] : this.priceRulesFormData.routeCode;
        this.routeDestOptions = this.priceRulesFormData.routeCode == null ? [] : this.priceRulesFormData.routeCode;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
