<template>
  <div>
    <vx-card class="" no-shadow card-border>
      <div class="vx-row mb-6">
        <div class="vx-col w-full align-center my-2">
          <span>Airport Extras Rule Criteria:</span>
        </div>
        <!--#region  suppliers field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Suppliers:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="
                airportExtraLocalForm.airportExtrasRuleCriteria.suppliers
              "
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.airportSupplier"
            />
          </div>
        </div>
        <!--#endregion  suppliers field-->

        <!--#region  Airport Extra Types field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Airport Extra Types:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              name="airportExtra"
              v-model="
                airportExtraLocalForm.airportExtrasRuleCriteria.extraTypes
              "
              :reduce="(x) => x.code"
              :disabled="readOnly"
              v-validate="{ rules: { required: false } }"
              :options="airportExtraLocalHelper.enums.airportExtraTypes"
            />
            <!-- <span class="text-danger text-sm" v-if="errors.has('airportExtra')">{{ errors.first('airportExtra') }}</span> -->
          </div>
        </div>
        <!--#endregion  Airport Extra Types field-->
      </div>
      <div class="vx-row mb-6">
        <!--#region Airport field -->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Airports:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="airportExtraLocalForm.airportExtrasRuleCriteria.airports"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.airportCode"
            />
            <span class="text-danger text-sm">{{
              errors.first("airports")
            }}</span>
          </div>
        </div>
        <!--#endregion Airports field ends -->
      </div>
    </vx-card>
  </div>
</template>

<script>
import _ from "lodash";
import { CustomValidator } from "../RewardRuleEditorValidator.js";
const customvalidator = new CustomValidator();
customvalidator.setupErrorMessages();

export default {
  name: "AirportExtrasCriteria",
  components: {},
  inject: ["$validator"],
  props: {
    helper: {
      type: Object,
    },
    form: {
      type: Object,
    },
    priceRulesFormData: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      airportExtraLocalHelper: this.helper,
      airportExtraLocalForm: this.form,
    };
  },

  methods: {
    validateform() {
      return this.$validator.validateAll();
    },
  },

  /**
   * When check box is unchecked, all its related form  should be reset   *
   */
  watch: {
    "form.productTypes": {
      deep: true,
      immediate: true,
      handler: function (newValue, oldValue) {
        if (
          oldValue &&
          oldValue.length > 0 &&
          oldValue.includes("AirportExtras") &&
          newValue &&
          !newValue.includes("AirportExtras")
        ) {
          this.airportExtraLocalForm.airportExtrasRuleCriteria = _.cloneDeep(
            this.airportExtraLocalHelper.airportExtrasCriteria
          );
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>