<template>
  <div>
    <vx-card class="" no-shadow card-border>
     
      <div class="vx-row mb-6">
        <!--#region  Operator  field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Operator:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="acomLocalForm.cruiseRuleCriteria.operators"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.operators"
            />
          </div>
        </div>
        <!--#endregion  Operator  field-->

        <!--#region  Country  field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Departure Country:</span>
          </div>
          <div class="mt-4">
            <v-select
              v-model="acomLocalForm.cruiseRuleCriteria.departureCountry"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.countryCode"
            />
          </div>
        </div>
        <!--#endregion  Country  field-->
      </div>
      
      <div class="vx-row mb-6">
        <!--#region  CabinTypes  field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Cabin Types:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="acomLocalForm.cruiseRuleCriteria.cabinTypes"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.cabinTypes"
            />
          </div>
        </div>
        <!--#endregion  CabinTypes  field-->

        <!--#region  CruiseType  field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Cruise Type:</span>
          </div>
          <div class="mt-4">
            <v-select
              v-model="acomLocalForm.cruiseRuleCriteria.cruiseType"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="acomLocalHelper.enums.cruiseTypes"
            />
          </div>
        </div>
        <!--#endregion  CruiseType  field-->

      </div>

      <!--#region Ship Names  field  -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full text-sm">
          <span>Ship Names:</span>
        </div>
        <template
          v-for="(name, nameIndex) in acomLocalForm.cruiseRuleCriteria
            .shipNames"
        >
          <!-- Origin -->
          <div class="vx-col w-full md:w-1/3" :key="nameIndex + '0'">
            <div class="mt-4">
              <label class="text-sm">MatchType</label>
              <v-select
                class="w-full"
                :name="'shipNameMatch_' + nameIndex"
                v-model="
                  acomLocalForm.cruiseRuleCriteria.shipNames[nameIndex]
                    .matchType
                "
                :options="acomLocalHelper.enums.matchTypes"
                :reduce="(option) => option.code"
                v-validate="{ rules: { required: true} }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('shipNameMatch_' + nameIndex)"
                >{{
                  errors
                    .first("shipNameMatch_" + nameIndex)
                    .replace("shipNameMatch_" + nameIndex, "Ship Name match type")
                }}</span
              >
            </div>
          </div>
          <!-- Value -->
          <div class="vx-col w-full md:w-1/3" :key="nameIndex + '1'">
            <div class="mt-4">
              <label class="text-sm">Value</label>
              <vs-input
                class="w-full"
                :name="'shipNameValue_' + nameIndex"
                v-model="
                  acomLocalForm.cruiseRuleCriteria.shipNames[nameIndex].value
                "
                v-validate="{ rules: { required: true } }"
                :disabled="readOnly"
              />
              <span class="text-danger text-sm" v-if="errors.has('shipNameValue_' + nameIndex)">{{ errors.first('shipNameValue_'+nameIndex).replace('nameValue_'+nameIndex, "Ship Name value") }}</span>
            </div>
          </div>
          <div class="vx-col w-full md:w-1/3" :key="nameIndex + '2'">
            <div class="mt-3">
              <!-- <label class="text-sm">Max</label> -->
              <vs-button
                @click="removeAcomDynamicFields(nameIndex, 'shipNames')"
                size="small"
                class="mt-8"
                type="border"
                color="danger"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Remove</vs-button
              >
            </div>
          </div>
        </template>

        <div class="vx-col w-full my-3">
          <vs-button
            @click="addAcomDynamicFields('shipNames')"
            size="small"
            type="border"
            color="success"
            :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="readOnly"
            >Add</vs-button
          >
        </div>
      </div>
      <!--#endregion Ship Name fields field ends -->

      <!--#region Cruise Names  field  -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full text-sm">
          <span>Cruise Names:</span>
        </div>
        <template
          v-for="(name, nameIndex) in acomLocalForm.cruiseRuleCriteria
            .cruiseNames"
        >
          <!-- Origin -->
          <div class="vx-col w-full md:w-1/3" :key="nameIndex + '0'">
            <div class="mt-4">
              <label class="text-sm">MatchType</label>
              <v-select
                class="w-full"
                :name="'cruiseNameMatch_' + nameIndex"
                v-model="
                  acomLocalForm.cruiseRuleCriteria.cruiseNames[nameIndex]
                    .matchType
                "
                :options="acomLocalHelper.enums.matchTypes"
                :reduce="(option) => option.code"
                v-validate="{ rules: { required: true} }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('cruiseNameMatch_' + nameIndex)"
                >{{
                  errors
                    .first("cruiseNameMatch_" + nameIndex)
                    .replace("cruiseNameMatch_" + nameIndex, "Cruise Name match type")
                }}</span
              >
            </div>
          </div>
          <!-- Value -->
          <div class="vx-col w-full md:w-1/3" :key="nameIndex + '1'">
            <div class="mt-4">
              <label class="text-sm">Value</label>
              <vs-input
                class="w-full"
                :name="'cruiseNameValue_' + nameIndex"
                v-model="
                  acomLocalForm.cruiseRuleCriteria.cruiseNames[nameIndex].value
                "
                v-validate="{ rules: { required: true } }"
                :disabled="readOnly"
              />
              <span class="text-danger text-sm" v-if="errors.has('cruiseNameValue_' + nameIndex)">{{ errors.first('cruiseNameValue_'+nameIndex).replace('cruiseNameValue_'+nameIndex, "Cruise Name value") }}</span>
            </div>
          </div>
          <div class="vx-col w-full md:w-1/3" :key="nameIndex + '2'">
            <div class="mt-3">
              <!-- <label class="text-sm">Max</label> -->
              <vs-button
                @click="removeAcomDynamicFields(nameIndex, 'cruiseNames')"
                size="small"
                class="mt-8"
                type="border"
                color="danger"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Remove</vs-button
              >
            </div>
          </div>
        </template>

        <div class="vx-col w-full my-3">
          <vs-button
            @click="addAcomDynamicFields('cruiseNames')"
            size="small"
            type="border"
            color="success"
            :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="readOnly"
            >Add</vs-button
          >
        </div>
      </div>     
      

      <!--#endregion Cruise Name fields field ends -->
    </vx-card>
  </div>
</template>

<script>
import _ from "lodash";
import { CustomValidator } from "../PricingRuleEditorValidator.js";
import StarRating from "vue-star-rating";
const customvalidator = new CustomValidator();
customvalidator.setupErrorMessages();

export default {
  name: "CruiseRuleCriteria",
  components: { StarRating },
  inject: ["$validator"],
  props: {
    helper: {
      type: Object,
    },
    form: {
      type: Object,
    },
    priceRulesFormData: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      acomLocalHelper: this.helper,
      acomLocalForm: this.form,
    };
  },

  methods: {
    validateform() {
      return this.$validator.validateAll();
    },
    //#region  Adding dynamic route fields in the form
    addAcomDynamicFields(fieldType) {
      if (fieldType === "shipNames") {
        this.acomLocalForm.cruiseRuleCriteria.shipNames.push(
          _.cloneDeep(this.acomLocalHelper.stringMatch)
        );
      }
      
      if (fieldType === "cruiseNames") {
        this.acomLocalForm.cruiseRuleCriteria.cruiseNames.push(
          _.cloneDeep(this.acomLocalHelper.stringMatch)
        );
      }

    },
    //#endregion

    //#region  Removing dynamic route fields in the form
    removeAcomDynamicFields(index, fieldType) {
      if (fieldType === "shipNames") {
        this.acomLocalForm.cruiseRuleCriteria.shipNames.splice(index, 1);
      }
      if (fieldType === "cruiseNames") {
        this.acomLocalForm.cruiseRuleCriteria.cruiseNames.splice(index, 1);
      }
    },
    //#endregion
  },
  /**
   * When check box is unchecked, all its related form  should be reset
   */
  watch: {
    "form.productTypes": {
      deep: true,
      immediate: true,
      handler: function (newValue, oldValue) {
        if (
          oldValue &&
          oldValue.length > 0 &&
          oldValue.includes("Cruise") &&
          newValue &&
          !newValue.includes("Cruise")
        ) {
          this.acomLocalForm.cruiseRuleCriteria = _.cloneDeep(
            this.acomLocalHelper.cruiseRuleCriteria
          );
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>