import axios from "@/axios.js"
import _ from 'lodash'
import helper from "./rewardRuleHelper"

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    rewardRuleStore: [],
  },
  mutations: {
   
  },
  actions: {
    addRule({ commit }, payload) {
      // disabled to use dummy data
      return new Promise((resolve, reject) => {
        const url = helper.StringFormat(helper.rewardRuleAPIs.addAPI, payload.applicationId);
        axios.post(url, { ruleData: JSON.stringify(payload.data) })
          .then(response => {
            /**
             * Eventually, fetchPriceRules will be executed upon successfull add request
             * So, commit here may not be needed
             * uncomment the following lines if needed
             */
            // commit('ADD_PRICE_RULE', {
            //   ruleId: response.data.ruleId,
            //   ruleAppId: payload.data.applicationId,
            //   ruleName: payload.data.name,
            //   rulePriority: payload.data.priority,
            //   ruleIsActive: payload.data.isActive,
            //   ruleCreated: response.data.ruleCreated,
            // });
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
    updateRule({ commit }, payload) {
      return new Promise((resolve, reject) => {
      const url = helper.StringFormat(helper.rewardRuleAPIs.updateAPI, payload.applicationId);
      axios.put(url, { ruleData: JSON.stringify(payload.data) })
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })

    },
  }
}
